 
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import validator from "validator";

const LoginTemplate = () => {
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(false);
  const [data, setData] = useState({ email: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const notify = (msg) => toast.success(msg);
  const notifyError = (msg) => toast.error(msg);

  const handleSubmit = (e) => {
    e.preventDefault();
    getCustomerDetails();
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (name === "email") {
      setData((prevData) => ({ ...prevData, [name]: value }));
      if (!validator.isEmail(value)) {
        setIsDisabled(true);
        setError("Please enter a valid email!");
      } else {
        setError("");
        setIsDisabled(false);
      }
    }
  };

  const getCustomerDetails = () => {
    setLoading(true);
    fetch(`https://africamarkets.net/ghmarketconnect/?ghw=login&svg=get_cust_details&email=${data.email}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((json) => {
        if (json["results"] && json["results"].length > 0) {
          setLoading(false);
          handleCustomerDetail();
        } else {
          setLoading(false);
          setError("Email does not exist, please provide an existing email!");
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("An error occurred while fetching data.");
        console.error("Fetch Error:", error);
      });
  };

  const handleCustomerDetail = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `https://africamarkets.net/ghmarketconnect/?ghw=login&svg=resetpassword`,
        JSON.stringify(data)
      );
      // console.log(response)

      if (response.data) {
        notify("Success: An email has been sent to your email address");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      notifyError("Error occurred while sending request");
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="limiter">
        <div className="container-login100">
          <div className="wrap-login100">
            <form
              className="login100-form validate-form p-l-55 p-r-55 p-t-178"
              onSubmit={handleSubmit}
            >
              <span className="login100-form-title">Password Reset</span>

              <div
                className="wrap-input100 validate-input m-b-20"
                data-validate="Please enter email"
              >
                <input
                  id="email"
                  className="input100"
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleInput}
                  placeholder="Enter email"
                  required
                />
                <span className="focus-input100"></span>
              </div>
              {error && (
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              )}

              <div className="container-login100-form-btn">
                <button
                  type="submit"
                  className="login100-form-btn"
                  disabled={isDisabled || loading}
                >
                  {loading ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Reset Password"
                  )}
                </button>
              </div>

              <div className="flex-col-c p-t-120 p-b-40"></div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LoginTemplate;