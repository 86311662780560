import React, { useEffect, useState, Suspense, useContext } from "react";
import { useDispatch } from "react-redux";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import { add } from "../store/cartSlice";
import { addWishList } from "../store/wishListSlice";
import { addToCompare } from "../store/compareSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Rating from "../components/Rating";
import shop_nav_img01 from "../assets/img/product/shop_nav_img01.jpg";
import shop_nav_img02 from "../assets/img/product/shop_nav_img02.jpg";
import shop_nav_img03 from "../assets/img/product/shop_nav_img03.jpg";
// import RecommendedProds from "../components/RecommendedProds";
import Header from "../components/Header";
import Productsimages from "../components/Productsimages";
// import Sizes from "../components/variations/Sizes";
// import ProductColor from "../components/variations/ProductColor";
import ProdImageSkeleton from "../components/ProdImageSkeleton";
import RecommendedProdsSkeleton from "../components/RecommendedProdsSkeleton";
import ProductVariations from "../components/ProductVariations";
// import Material from "../components/Variations/Material";
import { useProductData } from "../components/ProductsContextApi";


function Shopdetail() {
  let { prodcode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [quantity, setQuantity] = useState(1);
  const [selectedVariations, setSelectedVariations] = useState({});

  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const [sku, setSKU] = useState("");
  const notify = (msg) => toast.success(msg);
  const [sellerregion, setRegion] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState(null);


  const [data, setData] = useState({})
  const [recommendedProducts, setRecommendedProducts] = useState();




  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal; 


    const getAllProducts = () => {
      fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=details&prcode=${prodcode}`, {signal})
        .then((response) => response.json())
        .then((json) => {
          const productData = json["results"][0];
          setData(productData);

          // Make the second API call using the data from the first API call
          return fetch(
            `https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=recommendedproducts&catcode=${productData.categorycode}&subcatcode=${productData.subcategorycode}`
          ).then((response) => response.json())
            .then((json) => {
              setRecommendedProducts(
                json?.results?.filter((product) => product.brand !== productData.brand) || []
              );
            });
        })
        .catch((error) => {
          if(error.name == "AbortError"){
            // console.log("cancelled");
          } else{
            console.error('Error fetching data', error);
          }
        });
    };

    getAllProducts();


    return () => {
      controller.abort();
    }
  }, [prodcode]);


  const Swal = require("sweetalert2");

  
  const addToCart = (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype,
    quantity,
    categorycode,
    subcategorycode,
    sellername,
    selectedVariations
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
      quantity,
      categorycode,
      subcategorycode,
      sellername,
      selectedVariations,
    };
    // dispatch an add action
    //notify("Product added successfully!");
    Swal.fire({
      title: "Add To Cart",
      text: "Product successfully added to cart!",
      icon: "success"
    });
    dispatch(add(product));
  };

  const getRegion = async () => {
    
    try{
      
      const response = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=productservices&svg=sellerRegion&prcode=${data.sellercode}`);
      const json = await response.json();
      // const region = json;
      setRegion(json)
      // console.log(json);

    }catch (error){
      console.error("Error fetching region data",error);
    }
    
  };

  useEffect(() => {
    getRegion();
  });
  

  const addToWishList = async (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
    };
    // dispatch an add action
    notify("Product saved to your wishlist successfully!");
    dispatch(addWishList(product));
    // Update productservices tb with count

    const res = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=wishlist`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prodcode,
        name,
        price,
        description,
        picture,
        model,
        sellercode,
        servicetype,
      }),
    });
    const response = await res.json();
    //console.log(response);
  };

  const addToCompareList = (
    prodcode,
    name,
    price,
    description,
    picture,
    model,
    sellercode,
    servicetype
  ) => {
    const product = {
      prodcode,
      name,
      price,
      description,
      picture,
      model,
      sellercode,
      servicetype,
    };
    // dispatch an add action
    notify("Product successfully added to your compare list!");
    dispatch(addToCompare(product));
  };
  const handleSelectVariations = (variations) => {
    setSelectedVariations(variations);
  };

  const descQty = () => {
    setQuantity(quantity > 1 ? quantity - 1 : 1);
    //console.log(`Qty: ${quantity}`);
  };

  const incrQty = () => {
    setQuantity(quantity + 1);
    //console.log(`Qty: ${quantity}`);
  };

  return (
    <div>
      <Header />

      <main>
        <div className="breadcrumb-area-two">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-wrap">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {/* shop single */}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shop-details-area pt-80 pb-90">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div className="shop-details-img-wrap">
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane show active"
                      id="nav-item-one"
                      role="tabpanel"
                      aria-labelledby="nav-item-one-tab"
                    >
                      <div className="shop-details-img" style={{ position: "relative" }}>
                        {data.previewpicture || data.picture !== "" ? (
                             <>
                          <img src={data.previewpicture || data.picture} alt="img" />
                          {data.freedelivery === "1" && (
                            <div
                              style={{
                                position: "absolute",
                                top: "1px", // Adjust the position as needed
                                right: "3px", // Change this to `left: "10px"` for the left corner
                                backgroundColor: "#F6921E", // Orange background
                                color: "white", // White text color
                                padding: "1px 10px", // Padding for better appearance
                                fontSize: "12px", // Adjust text size as needed
                                borderRadius: "5px", // Rounded corners
                                height: "20px",
                              }}
                            >
                              Free Delivery
                            </div>
                          )}
                          </>
                        ) : (
                          <ProdImageSkeleton />
                        )}
                      </div>
                    </div>

                    <div
                      className="tab-pane"
                      id="nav-item-two"
                      role="tabpanel"
                      aria-labelledby="nav-item-two-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img01} alt="img" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="nav-item-three"
                      role="tabpanel"
                      aria-labelledby="nav-item-three-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img02} alt="img" />
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="nav-item-four"
                      role="tabpanel"
                      aria-labelledby="nav-item-four-tab"
                    >
                      <div className="shop-details-img">
                        <img src={shop_nav_img03} alt="img" />
                      </div>
                    </div>
                  </div>
                </div>

                <Suspense fallback={<div>Loading...</div>}>
                  {/* Lazy load the Productsimages component */}
                  <Productsimages productcode={prodcode} />
                </Suspense>
              </div>
              <div className="col-xl-5 col-lg-6 col-md-8">
                <div className="shop-details-content">
                  {/* <span>
                    <i className="fa-solid fa-check"></i>In Stock
                  </span> */}
                  <h2 className="title">{data.name}</h2>

                  <div className="shop-details-price">
                    <h2 className="title">{data.price ? `Gh₵${data.price.toFixed(2)}` : ""}</h2>
                    {/* <h4 className="stock-status">- IN Stock</h4> */}
                  </div>
                  <p>{data.description}</p>
                  <div className="shop-details-bottom">
                    {data.servicetype === "1" ? (
                      <>
                        <ul>
                          <li
                            className="sd-sku"
                            style={{ paddingBottom: "20px" }}
                          >
                            <span className="title">Region/State :</span>
                            {sellerregion || ""}
                          </li>
                        </ul>
                      </>
                    ) : (
                      <ProductVariations
                        onSelectVariations={handleSelectVariations}
                      />
                    )}
                  </div>
                  <div className="shop-details-quantity">
                    {data.servicetype === "1" ? (
                      ""
                    ) : (
                      <>
                        {/* <span>Quantity :</span> */}
                        <div className="cart-plus-minus">
                          {/* <input
                            type="text"
                            defaultValue="1"
                            // onChange={() => setQuantity(e.target.value)}
                          /> */}
                          <div className="cart-plus-minus">
                            <input type="text" value={quantity} minLength={1} />
                            <div
                              className="dec qtybutton"
                              onClick={() => descQty()}
                            >
                              -
                            </div>
                            <div
                              className="inc qtybutton"
                              onClick={() => incrQty()}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {data.servicetype === "1" ? (
                      data.price ? (
                        <NavLink
                          to={`/service/request/${prodcode}`}
                          className="wishlist-btn"
                        >
                          <i className="fa-solid fa-gear"></i>Request Service
                        </NavLink>
                      ) : (
                        <NavLink
                          to={`/service/request/${prodcode}`}
                          className="wishlist-btn"
                        >
                          <i className="fa-solid fa-gear"></i>Request Service
                        </NavLink>
                      )
                    ) : (
                      <div className="shop-details-quantity mt-30 ">
                        <NavLink
                          //   to="shop-details.html"
                          className="wishlist-btn px-5"
                          onClick={() =>
                            addToCart(
                              prodcode,
                              data.name,
                              data.price,
                              data.description,
                              data.previewpicture || data.picture,
                              data.model,
                              data.sellercode,
                              data.servicetype,
                              quantity,
                              data.categoryCode,
                              data.subcategoryCode,
                              data.sellername,
                              selectedVariations
                            )
                          }
                        >
                          <i className="fa-solid fa-cart-arrow-down  "></i> Add
                          to Cart
                        </NavLink>
                        {/* <NavLink to="shop-details.html" className="cart-btn">
                          Buy now
                        </NavLink> */}
                      </div>
                    )}
                  </div>
                  <div style={{ marginTop: "-35px", marginBottom: "25px" }}>
                    {" "}
                    <Rating prodcode={prodcode} />
                  </div>
                  <div className="shop-details-Wishlist">
                    <ul>
                      <li>
                        <NavLink
                          onClick={() =>
                            addToWishList(
                              prodcode,
                              data.name,
                              data.price,
                              data.description,
                              data.previewpicture || data.picture,
                              data.model,
                              data.sellercode,
                              data.servicetype
                            )
                          }
                        >
                          <i className="fa-regular fa-heart"></i>Add to Wishlist
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={() =>
                            addToCompareList(
                              prodcode,
                              data.name,
                              data.price,
                              data.description,
                              data.previewpicture || data.picture,
                              data.model,
                              data.sellercode,
                              data.servicetype
                            )
                          }
                        >
                          <i className="fa-solid fa-chart-column"></i>Compare
                        </NavLink>
                      </li>
                      {/* <li className="sd-share">
                        <span className="title">Share Now :</span>
                        <NavLink to="#">
                          <i className="fab fa-facebook-f"></i>
                        </NavLink>
                        <NavLink to="#">
                          <i className="fab fa-twitter"></i>
                        </NavLink>
                        <NavLink to="#">
                          <i className="fab fa-linkedin-in"></i>
                        </NavLink>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-12 col-md-4">
                <div className="recommended-item-wrap">
                  <div className="recommended scroll">
                    <span>Recommended For You :</span>
                    {recommendedProducts ? (
                      recommendedProducts.map((prod) => {
                        return (
                          <div className="recommended-item mb-25" key={prod.id}>
                            <div className="thumb"  style={{ position: "relative" }}>
                              <NavLink to={`/shopdetail/${prod.code}`}>
                                <img
                                  src={prod.previewpicture || prod.picture}
                                  alt="img"
                                  style={{ cursor: "pointer" }}
                                />
                                {prod.freedelivery === '1' && (
      <div
        style={{
          position: "absolute",
          top: "1px",   // Adjust the position as needed
          right: "3px", // Change this to `left: "10px"` for the left corner
          backgroundColor: "#F6921E", // Orange background
          color: "white", // White text color
          padding: "1px 10px", // Padding for better appearance
          fontSize: "12px", // Adjust text size as needed
          borderRadius: "5px", // Rounded corners
          height:"20px",
        }}
      >
        Free Delivery
      </div>
    )}
                              </NavLink>
                            </div>
                            <div className="content">
                              <h6
                                className="title text-center"
                                style={{ cursor: "pointer" }}
                              >
                                <NavLink to={`/shopdetail/${prod.code}`}>
                                  {prod.name}
                                </NavLink>
                              </h6>
                              <h5 className="price text-center">{prod.price ? `GH₵ ${Number(prod.price).toFixed(2).toLocaleString()}` : ''}</h5>
                              <ul>
                                <li className="text-center">
                                  by{" "}
                                  <NavLink to={`/shopitems/${prod.sellercode}`}>
                                    {prod.sellername}
                                    </NavLink>
                                    {/* Conditional Icon Display */}
      {prod.classify === "1" && (
        <i className="fa fa-check-circle" style={{ marginLeft: "5px", color:"#F6921E" }}></i>
      )}
      {prod.classify === "2" && (
        <i className="fa fa-star" style={{ marginLeft: "5px", color:"#F6921E" }}></i>
      )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <RecommendedProdsSkeleton />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* <ToastContainer /> */}
    </div>
  );
}

export default Shopdetail;
