import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/Header";
import "../assets/css/cart.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/connect";
import { emptyCart } from "../store/cartSlice";
import momo from "../assets/img/payment/momo.webp";
import vodacash from "../assets/img/payment/vodacash.png";
import atmoney from "../assets/img/payment/atmoney.png";
import cards from "../assets/img/payment/cards.png";

import ServicePaySummary from "../components/ServicePaySummary";
import { event } from "jquery";
import axios from "axios";

const CheckoutServiceSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [payInfo, setPayInfo] = useState({});
  const [payLoading, setPayLoading] = useState(false);
  const [trackInfo, setTrackInfo] = useState({});
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [taxAmt, setTaxAmt] = useState(0);
  const [paymentlink, setPaylink] = useState("");
  const [result, setResult] = useState({});
  const track = localStorage.getItem(btoa("tckcd"));

  const [toSummary, setToSummary] = useState({});
  const [data, setData] = useState({
    fname: "",
    lname: "",
    phonenumber: "",
    email: "",
    address: "",
    confirmemail: "",
    region: "",
  });
  


  const handleInput = (event) => {
    const {name, value} = event.target;

    setData((data)=> ({
      ...data,
      [name]:value,
    }));
  };


  const notify = (mode) =>
  mode !== "" && result["msg"] ? toast[mode](result["msg"]) : "";

  const handlePayment = async () => {
    setPayLoading(true);

    setData({
      fname: userInfo.firstname,
      lname: userInfo.lastname,
      phonenumber: userInfo.phonenumber,
      email: userInfo.email,
      address: userInfo.address,
      confirmemail: userInfo.email,
      region: userInfo.state,
    })
    


    const res = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=requestservice&svg=processpay`, {
     method: "POST",
     headers: {
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       fname: userInfo.firstname,
       lname: userInfo.lastname,
       phonenumber: userInfo.phonenumber,
       email: userInfo.email,
       address: userInfo.address,
       confirmemail: userInfo.email,
       region: userInfo.state,
       payoption: payInfo.paymentoption,
       payopotncontact: payInfo.paymentoptionnumber,
       trackno: track,
       tax: taxPercentage,
       taxamt: taxAmt,
       gtotal: (
         parseFloat(23) + parseFloat(taxAmt)
       ).toFixed(2),
     }),
   });
   const response = await res.json();
   setResult(response);

   if (response) {
     let mode = result["error"] === "1" ? "success" : "error";
     notify(mode);

     // save client info to localstorage
     // localStorage.setItem("cdi", JSON.stringify(""));

    //  console.log("userInfo");
    //  console.log(response["error"]);
     // unset user input

     if (response["error"]) {
      //  console.log("dispatching");
       // clear cart localstorage
       dispatch(emptyCart());
     }
     // localStorage.setItem("cart", JSON.stringify({}));
   }

  //  console.log("responseing");
  //  console.log(response);
   //console.log(response.data.paylink);

   const paylink = response.data.paylink;
   if (paylink) {
    //  console.log(paylink);
     setPaylink(paylink);

     // if (paymentlink) {
    //  console.log("Redirecting to Payment...");
     const timeout = setTimeout(() => {
       setPayLoading(false);
       window.location.assign(paylink);
     }, 2000);
     return () => clearTimeout(timeout);
     // }
   }

   //console.log("opportunity...");
   //console.log(paymentlink);
 };


   //get customer info
  //  const getCustomerInfo = async (data) => {
  //   console.log("logging here....");
  //   console.log(JSON.stringify(data));
  //   const res = await fetch(
  //     `https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=getcustomerinfo`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     }
  //   );
  //   const response = await res.json();
  //   console.log("Response from getting customer.."+Object.values(response));
  //   return response;
  // };

  const getTaxes = async () => {
    const res = await fetch(`https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=gettaxes`);
    const response = await res.json();
    return response;
  };

  useEffect(() => {
    // Fetch user information
    // console.log("hapenning here");
   
   
    const taxes = getTaxes();
    taxes.then((js) => {
      setTaxPercentage(js["data"]);
      setTaxAmt(
        ((taxPercentage * 23) / 100).toFixed(2)
      );
    });


    // console.log("taxes");
    // console.log(cusemail);
    // console.log(payid);

    // if (payid) {
    //   const autores = getCustomerInfo({
    //     cdi: cusemail,
    //     po: payid["po"],
    //     pon: payid["pon"],
    //   });

    //   autores.then(
    //     (value) => {
    //       // let arr = Object.values(value.data);
    //       // console.log("Response from getting customer.."+value);
    //       setUserInfo(value.data.cd);
    //       setPayInfo(value.data.pd);
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }else{
    //   console.log("Payment info not set");
    // }
  }, [result,
    paymentlink,
    taxAmt,
    taxPercentage,]);

    useEffect(() => {
      const payid = JSON.parse(localStorage.getItem(btoa("spid")));
      const cusemail = JSON.parse(localStorage.getItem(btoa("scdi")));
      const getCustomerInfo = () =>{
        var payload = {
          cdi: cusemail,
        po: payid["po"],
        pon: payid["pon"],
        }; axios.post(`https://africamarkets.net/ghmarketconnect/?ghw=processpayment&svg=getcustomerinfo`, JSON.stringify(payload), {
          headers:{
            'Content-Type':'application/json'
          }
        }).then(res=>{
          // console.log("responsessses..."+Object.values(res.data));
          setUserInfo(res.data.cd);
          setPayInfo(res.data.pd);
        });
      }

      getCustomerInfo();
      
      //  if (payid) {
      // const autores = getCustomerInfo({
      //   cdi: cusemail,
      //   po: payid["po"],
      //   pon: payid["pon"],
      // });

      

    //   autores.then(
    //     (value) => {
    //       // let arr = Object.values(value.data);
    //       // console.log("Response from getting customer.."+value);
    //       setUserInfo(value.data.cd);
    //       setPayInfo(value.data.pd);
    //       console.log("herer her her..."+Object.values(value.data));
    //     },
    //     (error) => {
    //       console.log(error);
    //     }
    //   );
    // }else{
    //   console.log("Payment info not set");
    // }
    },[userInfo]);


  return (
    <>
      <Header />

      <section className="coupon-product-area pt-30 pb-60">
        <div className="container pb-30">
          <div className="row mb-1">
            <NavLink to="/">
              <span>
                <i className="fa fa-arrow-left"></i> Go back & continue shopping
              </span>
            </NavLink>
          </div>
          <div className="row">
            <div className="col-sm-9 mb-20">
              <div className="card">
                <div className="card-header d-flex justify-content-between">
                  Customer Details
                  <span className="">
                    {/* <NavLink to="/checkout/address">
                      Change Customer Details
                    </NavLink> */}
                  </span>
                </div>
                <div className="card-body">
               
                  {typeof(userInfo) !== 'undefined' && Object.keys(userInfo).length > 0 ? (
                    <>
                      <div className="fw-bold mb-2">
                        {userInfo.firstname} {userInfo.lastname}
                      </div>
                      <address>
                        {userInfo.address} | {userInfo.city} |{" "}
                        {userInfo.phonenumber}
                      </address>
                    </>
                  ) : (
                    <>
                      <div className="fw-bold mb-2">Loading...</div>
                      <address>Loading Data ...</address>
                    </>
                  )}
                </div>
              </div>

              {/* <div className="card mt-20">
                <div className="card-header d-flex justify-content-between">
                  Delivery Details
                  <span className="">
                    <NavLink to="/checkout/shipping">
                      Add Delivery Details
                    </NavLink>
                  </span>
                </div>
              </div> */}

              <div className="card mt-20">
                <div className="card-header d-flex justify-content-between">
                  Payment method
                  <span className="">
                    <NavLink to="/checkout/servicepayment"> Change Payment Option</NavLink>
                  </span>
                </div>
                <div className="card-body">
                  {Object.keys(payInfo).length > 0 ? (
                    <div className="row mb-2">
                      <div className="d-flex flex-wrap gap-3 ">
                        <div className="card cursor-pointer">
                          <label className="m-0">
                            <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                              {/* <img
                                width="50"
                                src={payInfo?.paymentoption}
                                alt=""
                              /> */}
                              <span className="fs-12">
                                {payInfo?.paymentoption}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="row mb-2">
                      <div className="d-flex flex-wrap gap-3 ">
                        <div className="card cursor-pointer">
                          <span className="btn-plain btn-block click-if-alone d-flex gap-2 align-items-center cursor-pointer">
                            <span className="fs-12">Loading...</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Payment Summary */}
            <ServicePaySummary
              handlePayment={handlePayment}
              payLoading={payLoading}
              userInfo={userInfo}
              payInfo={payInfo}
              trackInfo={trackInfo}
            
            />
          </div>
        </div>
      </section>

      <ToastContainer />
    </>
  );
};

export default CheckoutServiceSummary;
